<template lang="pug">
  v-container#receipts-modal(fluid='' tag='section')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(v-model='dialogCalculator' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | SUMA DE CANTIDADES
        v-card-text
          v-row
            h3 Cantidad: {{product.cantidad}}
            v-col(cols="12" md='12' style='padding-top: 0; padding-bottom: 0;')
              v-text-field.purple-input(@keyup="dialogCalculator" type="text" @keypress="isNumber($event)" label="Sumar Cantidad" v-model="sumCantVal")
            v-col(cols="12" sm='12' class='addButton').text-center
              v-btn(:disabled="receipt.id" color='green' @click="calculatorProduct").mt-4
                | SUMAR CANTIDAD
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='primary' text='' @click='dialogCalculator = false')
            | LISTO
    v-dialog(v-model='confirmationDialogProduct' persistent max-width='290')
      v-card
        v-card-title.text-h5
          | Confirmar
        v-card-text ¿Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='red darken-1' text='' @click='confirmationDialogProduct = false')
              | Cancelar
            v-btn.success-btn(text='' @click='acceptConfirmationDelete')
              | Aceptar
    v-dialog(fullscreen="" v-model='dialodInvMod')
      v-card
        inventoryDataModal(@closeModal="closeInventoryModal" :productToEdit='productToEdit' :forEditProduct='forEditProduct')
    v-dialog(v-model='dialogAddProduct' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Productos
        v-card-text
          v-form(ref='formAddProduct' v-model='validAddProduct' lazy-validation='')
            v-row
              v-col(cols='10' md='11' style='padding-top: 0; padding-bottom: 0;')
                v-autocomplete(:rules='[rules.required]' @change="getProductValue(product, product.producto.activeValue)" v-model='product.producto' :items='productsLst' return-object='return-object' item-text='completeData' label='Productos' autofocus)
              v-col.py-0.pl-0.ml-0(cols="2" md="1")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="receipt.id" v-on="on" icon text color="purple darken-3" @click="addNewProduct" style='background:#B18BE2;')
                      v-icon(style="font-size: 25px;") mdi-plus
                  span.tooltips Crear Producto
              v-col(cols="10" md='3' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(@keyup="calculateSubtotal" :disabled="receipt.id" type="text" @keypress="isNumber($event)" :rules='[rules.required]' label="Cantidad" v-model="product.cantidad")
              v-col.py-0.pl-0.ml-0(cols="2" md="1")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="receipt.id" v-on="on" icon text color="white" @click="openDialogCalculator" style='background: green;')
                      v-icon(style="font-size: 25px;") mdi-calculator
                  span.tooltips Calculadora
              v-col(cols="12" md='4' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(@keyup="calculateSubtotal" :disabled="receipt.id" type="text" @keypress="isNumber($event)" :rules='[rules.required]' label="Precio" v-model="product.valorUnitario")
              v-col(cols="12" md='4' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(readonly :disabled="receipt.id" type="number" :rules='[rules.required]' label="Total" v-model="product.total")
              v-col(cols="12" style='padding-top: 0; padding-bottom: 0;')
                v-alert(v-model="alertProductData" dismissible dense='' border='left' type='error')
                  | {{msgAlertProducts}}
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogAddProduct = false')
            | Cancelar
          v-btn(color='primary' text='' @click='addProduct')
            | Agregar
    v-card
      v-toolbar(v-if="!receipt.id" text='' color='primary' dark='')
        v-toolbar-title Recibo {{secuencialRecibo.toString().padStart(9, '0')}}
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeReceiptModal')")
            v-icon.primary.rounded mdi-close
      v-toolbar(v-if="receipt.id" text='' color='primary' dark='')
        v-toolbar-title Recibo {{receipt.secuencial.toString().padStart(9, '0')}}
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeReceiptModal')")
            v-icon.primary.rounded mdi-close
      v-card-text(style="padding-top: 30px;")
        v-row
          v-form(ref='formAddReceipt' v-model='validAddReceipt' lazy-validation='' style="padding: 5px; width: 100%; margin: 5px;")
            v-row
              v-col.py-0(cols="12" md="2")
                v-menu(:disabled="receipt.id" ref='startMenu1' v-model='startMenu1' :close-on-content-click='false' :nudge-right='40' :return-value.sync='receipt.fechaEmision' transition='scale-transition' min-width='290px' offset-y='')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly dense="" :rules='[rules.required]' v-model='computedDateFormattedMomentjs' prepend-icon='mdi-calendar' v-on='on')
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Fecha de Emisión' }}
                  v-date-picker.my-0(:rules='[rules.required]' v-model='receipt.fechaEmision' no-title='' scrollable='' locale='es-ec' @change="$refs.startMenu1.save(receipt.fechaEmision)" color="green lighten-1")
              v-col.py-0(cols="12" md="5")
                v-autocomplete(@change="changeProvider" :disabled="receipt.id" dense="" :rules='[rules.required]' v-model='receipt.proveedor' :items='providersLst' return-object='return-object' item-text='completeData' label='Proveedor Interno')
              v-col.py-0(cols="12" md="5")
                v-autocomplete(@change="changeProvider" :disabled="receipt.id" dense="" :rules='[rules.required]' v-model='receipt.proveedor' :items='providersLstExterno' return-object='return-object' item-text='completeData' label='Proveedor Externo')
              v-col.py-0(cols="12" md="6")
                v-autocomplete(:disabled="receipt.id" dense="" v-model='advanceSelected' :items='advanceLoadProviderLst' return-object='return-object' :item-text="getItemText" label='Anticipos')
              v-col(cols="12" md='3' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(dense=""  :disabled="receipt.id || advanceSelected === null" type="text" @keypress="isNumber($event)" label="Pagar Anticipo" v-model="payAdvance")
              v-col(cols="12" md='3' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(dense=""  @keyup="addTransporte" :disabled="receipt.id" type="number" label="Transporte" v-model="receipt.transporte")
              v-col(cols="12" md='12' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(:disabled="receipt.id" type="text" :rules='[rules.required]' label="Usuario" v-model="receipt.usuario")
              v-col(cols="12" md='12' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(:disabled="receipt.id" type="text" label="Observaciones" v-model="receipt.observaciones")
            v-row(style="padding-right: 20px;")
          v-col(cols="12" md="10")
            v-row
              v-col(cols="7" md="7")
                h3(style="padding-left: 50px; padding-top: 20px;") Lista de Productos
              v-col(cols="4" sm='2' class='addButton').text-right
                v-btn(:disabled="receipt.id" color='green' @click="addNewElement").mt-4
                  | AGREGAR PRODUCTOS
              v-col(cols="4" sm='2' class='addIcon').text-right
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="receipt.id" v-on="on" icon='' style='background: transparent;' color='green').mt-4
                      v-icon(style="font-size: 40px;" @click="addNewElement") mdi-clipboard-plus
                  span.tooltips Agregar Productos
              v-col(cols="12")
                v-data-table(:headers="headers" :items="receipt.listaProductos" item-key="id" dense fixed-header :loading="cargando" loading-text="Cargando" :footer-props="{\'items-per-page-text':'Productos por página'\}")
                  template(v-slot:item.action="{ item }")
                    v-tooltip(bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(v-if="!receipt.id" text="" icon="" color='blue' v-on="on" @click="editElement(item)")
                          v-icon(small) mdi-lead-pencil
                      span.tooltips Editar
                    v-tooltip(bottom="")
                      template(v-slot:activator="{ on }")
                        v-btn(v-if="!receipt.id" text="" icon="" color='red' v-on="on" @click="openDeleteItem(item)")
                          v-icon(small) mdi-trash-can-outline
                      span.tooltips Eliminar
                  v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                    | Sin resultados
                  v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                    | No se tiene datos
                  v-flex(xs12='', sm10='', offset-sm1='')
          v-col(cols="12" md="2")
            v-row
              v-col(cols="12")
                v-row
                  v-col(cols="12" md="12" style='width: 100%; text-align: right; padding: 0px;')
                    v-row
                      v-col(cols="6")
                        label.label-tot(style='width: 100px;') TOTAL:
                      v-col(cols="6")
                        label.label-val(style='font-size: 12px;') ${{receipt.total}}
              v-col(cols="12")
                v-row
                  v-col(cols="4").text-center
                    v-tooltip(bottom='')
                      template(v-slot:activator='{ on, attrs }')
                        v-btn(v-bind='attrs' v-on='on' icon='' color='blue' style="margin: 10px; background: transparent;" :loading="loadingPrintReceipts").mt-4
                          v-icon(style="font-size: 40px;" @click="printRecibo") mdi-printer
                      span.tooltips Imprimir Recibo
                  v-col(cols="4").text-center
                    v-tooltip(bottom='')
                      template(v-slot:activator='{ on, attrs }')
                        v-btn(v-bind='attrs' v-on='on' :disabled="receipt.id" icon='' color='orange' style="margin: 10px; background: transparent;").mt-4
                          v-icon(style="font-size: 40px;" @click="cleanOperation") mdi-file-refresh
                      span.tooltips Limpiar Todo
                  v-col(cols="4").text-center
                    v-tooltip(bottom='')
                      template(v-slot:activator='{ on, attrs }')
                        v-btn(v-bind='attrs' v-on='on' :disabled="receipt.id" icon='' color='primary' style="margin: 10px; background: transparent;" :loading="loadingSave" @click="guardarRecibo").mt-4
                          v-icon(style="font-size: 40px;") mdi-content-save
                      span.tooltips Guardar
</template>
<script>
  import moment from 'moment'
  import * as printerReceipt from '../../../../functions/printerReceipt.js'
  var accounting = require('accounting')
  export default {
    components: {
      InventoryDataModal: () => import('@/views/dashboard/component/application/InventoryDataModal'),
    },
    props: {
      receiptToEdit: {
        type: Object,
        default: function () {
          return {
            usuario: '',
            secuencial: '',
            fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
            proveedor: null,
            listaProductos: [],
            observaciones: '',
            total: '',
            transporte: '',
            horaCreado: null,
            advanceSelected: null,
            payAdvance: '',
          }
        },
      },
    },
    data: () => ({
      selectedProduct: null,
      dateUntilAdvance: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFromAdvance: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0).toISOString().substr(0, 10),
      advanceSelected: null,
      payAdvance: '',
      sumCantVal: '',
      dialogCalculator: false,
      deleteItemSend: null,
      confirmationDialogProduct: false,
      alertProductData: false,
      msgAlertProducts: '',
      productToEdit: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '0',
          texto: '0%',
          valor: '0',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
      },
      product: {
        producto: null,
        cantidad: '',
        descripcion: '',
        valorUnitario: '',
        total: '',
        porcentajecero: '',
      },
      validAddProduct: true,
      dialogAddProduct: false,
      loadingPrintReceipts: false,
      loadingSave: false,
      headers: [
        { text: 'Cantidad', value: 'cantidad', align: 'left', filterable: true },
        { text: 'Código', value: 'producto.codArticle', align: 'left', filterable: true },
        { text: 'Nombre', value: 'producto.name', align: 'left', filterable: true },
        { text: 'Valor Unitario', value: 'valorUnitario', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
        { text: 'Acciones', align: 'center', value: 'action', sortable: false }, // No se cambia
      ],
      cargando: false,
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      startMenu1: false,
      receipt: {
        usuario: '',
        secuencial: '',
        fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
        proveedor: null,
        listaProductos: [],
        observaciones: '',
        total: '',
        transporte: '',
        horaCreado: null,
        advanceSelected: null,
        payAdvance: '',
      },
      validAddReceipt: true,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      forEditProduct: false,
      dialodInvMod: false,
      editProduct: false,
      banSendReceipt: '0',
    }),
    computed: {
      computedDateFormattedMomentjs () {
        return this.receipt.fechaEmision ? moment(this.receipt.fechaEmision).format('DD/MM/YYYY') : ''
      },
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
      providersLstExterno () {
        return this.$store.state.provider.lstProvidersExternos
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
      secuencialRecibo () {
        return this.$store.state.receipt.secuenciaRecibo
      },
      productsLst () {
        return this.$store.state.product.lstProductsActive
      },
      advanceLoadProviderLst () {
        return this.$store.state.advance.advancesProviderLst
      },
    },
    watch: {
      receiptToEdit () {
        this.receipt = this.receiptToEdit
      },
    },
    methods: {
      getItemText (item) {
        return `FECHA: ${item.createDate} - SALDO: ${item.balance}`
      },
      openDialogCalculator () {
        this.product.cantidad = 0
        this.dialogCalculator = true
        this.calculateSubtotal()
      },
      calculatorProduct () {
        if (this.sumCantVal) {
          if (this.sumCantVal !== '') {
            this.product.cantidad = accounting.toFixed(parseFloat(this.product.cantidad) + parseFloat(this.sumCantVal), 2)
            this.calculateSubtotal()
            this.sumCantVal = ''
          }
        }
      },
      changeProvider () {
        this.receipt.listaProductos = []
        this.$store.dispatch('advance/loadAdvancesProvider', [this.dateFromAdvance, this.dateUntilAdvance, this.receipt.proveedor.providerKey])
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      addTransporte () {
        if (this.receipt.total) {
          if (this.receipt.total !== '') {
            if (this.receipt.transporte !== '') {
              this.receipt.total = accounting.toFixed(parseFloat(this.receipt.total) + parseFloat(this.receipt.transporte), 2)
            } else {
              this.calculateVals()
            }
          }
        }
      },
      deleteItem (item) {
        let cont = 0
        let ban = true
        while (cont < this.receipt.listaProductos.length && ban) {
          if (this.receipt.listaProductos[cont] === item) {
            this.receipt.listaProductos.splice(cont, 1)
            ban = false
          }
          cont++
        }
        this.calculateSubtotal()
        this.calculateVals()
      },
      acceptConfirmationDelete () {
        this.deleteItem(this.deleteItemSend)
        this.confirmationDialogProduct = false
      },
      openDeleteItem (item) {
        this.deleteItemSend = item
        this.confirmationDialogProduct = true
      },
      editElement (item) {
        this.editProduct = true
        this.dialogAddProduct = true
        this.banDescuento = true
        this.product = item
        this.dctoProduct = this.product.descuento
        this.alertProductData = false
      },
      getProductValue (product, activeVal, txtIva) {
        this.selectedProduct = product
        if (!product.producto.validChangeIva) {
          this.forEditProduct = true
          this.dialodInvMod = true
          this.productToEdit = product.producto
          return
        }
        let activeValueDef = activeVal
        if (product.producto.lstProviderPrice) {
          if (product.producto.lstProviderPrice.length > 0) {
            const providerLst = product.producto.lstProviderPrice.filter(item => {
              return item.provider.id === this.receipt.proveedor.id
            })
            if (providerLst.length > 0) {
              activeValueDef = providerLst[0].price
            }
          }
        }
        product.valorUnitario = parseFloat(activeValueDef.toString().replace(',', '.'))
        this.calculateSubtotal()
      },
      calculateSubtotal () {
        if (this.product.producto) {
          if (this.product.valorUnitario === null || this.product.valorUnitario === '') {
            this.disableAdd = true
            this.msgAlertProducts = 'Debe agregar el precio unitario del producto.'
            this.alertProductData = true
            return
          }
          const valRest = parseFloat(this.product.producto.quantity) - parseFloat(this.product.cantidad)
          if (valRest < 0 && this.product.producto.type.texto === 'BIEN' && !this.profileLoaded.negativeInventory) {
            this.disableAdd = true
            this.msgAlertProducts = 'La cantidad excede el número de productos en stock.'
            this.alertProductData = true
            return
          } else {
            this.disableAdd = false
          }
          this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
          if (this.dctoProduct && this.dctoProduct.toString() !== '') {
            if (this.banDescuento) {
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(this.dctoProduct)), 3)
              this.product.descuento = this.dctoProduct
            } else {
              const valDcto = accounting.toFixed(((parseFloat(this.product.total) * parseFloat(this.dctoProduct)) / 100), 3)
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(valDcto)), 3)
              this.product.descuento = valDcto
            }
          }
          if (parseFloat(this.product.total) < 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'El descuento no puede ser mayor que el valor total a pagar por produto.'
            this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
            this.banDescuento = true
            this.dctoProduct = ''
            this.product.descuento = this.dctoProduct
          } else {
            this.alertProductData = false
          }
        }
      },
      addNewElement () {
        if (this.receipt.proveedor === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el proveedor.',
          }
          return
        }
        this.dialogAddProduct = true
        this.product = {
          producto: null,
          cantidad: '',
          descripcion: '',
          valorUnitario: '',
          total: '',
          porcentajecero: '',
          retencionir: '',
          retencioniva: '',
          retencionirval: '0',
          retencionivaval: '0',
          cuentagasto: null,
          descuento: '',
        }
        this.editProduct = false
        this.dialogAddProduct = true
        this.product.cantidad = 1
        this.banDescuento = true
        this.dctoProduct = ''
        this.alertProductData = false
        this.disableAdd = false
      },
      printRecibo () {
        this.loadingPrintReceipts = true
        if (this.receipt.id) {
          this.receipt.secuencial = this.receipt.secuencial.toString().padStart(9, '0')
        } else {
          this.receipt.secuencial = this.secuencialRecibo.toString().padStart(9, '0')
        }
        printerReceipt.printReceipt(this.receipt, 'Recibo-' + this.receipt.secuencial).then(res => {
          this.loadingPrintReceipts = false
        })
      },
      async guardarRecibo () {
        if (this.banSendReceipt === '0') {
          this.banSendReceipt = '1'
          setTimeout(() => {
            this.banSendReceipt = '0'
          }, 1000)
          if (this.$refs.formAddReceipt.validate()) {
            if (this.receipt.listaProductos.length === 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Para continuar debe agregar los productos.',
              }
              return
            }
            if (!this.receipt.id) {
              this.loadingSave = true
              this.receipt.secuencial = this.secuencialRecibo
              this.receipt.advanceSelected = this.advanceSelected
              this.receipt.payAdvance = this.payAdvance
              await this.$store.dispatch('receipt/saveReceipt', this.receipt).then(res => {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Recibo de compra guardado de manera correcta.',
                }
                this.printRecibo()
                this.$emit('closeReceiptModal')
              }, reject => {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de guardar el recibo de compra.',
                }
              })
            }
          }
        }
      },
      addNewProduct () {
        this.forEditProduct = false
        this.dialodInvMod = true
        this.productToEdit = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '01',
            texto: 'BIEN',
          },
          quantity: '',
          activeValue: '',
          activeWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '0',
            texto: '0%',
            valor: '0',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
        }
      },
      addProduct () {
        this.viewTable = true
        if (this.$refs.formAddProduct.validate()) {
          if (parseFloat(this.product.cantidad) <= 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'La cantidad no puede ser menor de 1.'
            this.product.cantidad = 1
            this.calculateSubtotal()
            return
          }
          let cont = 0
          let ban = true
          if (!this.editProduct) {
            while (cont < this.receipt.listaProductos.length && ban) {
              if (this.receipt.listaProductos[cont].producto.codArticle.toString() === this.product.producto.codArticle.toString()) {
                ban = false
              }
              cont++
            }
            if (ban) {
              this.product.producto.description = this.product.producto.description.replace(/(\r\n|\n|\r)/gm, ' ')
              if (this.product.producto.description.length > 300) {
                this.product.producto.description = this.product.producto.description.substring(0, 299)
              }
              this.receipt.listaProductos.push(this.product)
            } else {
              if (!ban) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Producto ya agregado dentro de la factura puede modificar su cantidad.',
                }
                this.dialogAddProduct = false
                return
              }
            }
          }
          this.dialogAddProduct = false
          this.viewTable = true
          this.calculateVals()
          this.product = {
            producto: null,
            cantidad: '',
            descripcion: '',
            valorUnitario: '',
            total: '',
            porcentajecero: '',
            retencionir: '',
            retencioniva: '',
            retencionirval: '0',
            retencionivaval: '0',
            cuentagasto: null,
            descuento: '',
          }
          this.dctoProduct = ''
          this.banDescuento = true
        }
      },
      calculateVals () {
        let valTot = 0
        for (let i = 0; i < this.receipt.listaProductos.length; i++) {
          valTot = valTot + parseFloat(this.receipt.listaProductos[i].cantidad * this.receipt.listaProductos[i].valorUnitario)
        }
        const tot = parseFloat(accounting.toFixed(valTot, 2))
        this.receipt.total = accounting.toFixed(tot, 2)
        if (this.receipt.transporte) {
          if (this.receipt.transporte !== '') {
            if (this.receipt.total !== '') {
              this.receipt.total = accounting.toFixed(parseFloat(this.receipt.total) + parseFloat(this.receipt.transporte), 2)
            } else {
              this.addTransporte()
            }
          }
        }
      },
      async closeInventoryModal () {
        this.dialodInvMod = false
        if (this.selectedProduct) {
          this.getProductValue(this.selectedProduct, this.selectedProduct.producto.activeValue, this.selectedProduct.producto.tarifaiva.texto)
          this.selectedProduct = null
        }
      },
      cleanOperation () {
        this.receipt = {
          usuario: '',
          secuencial: '',
          fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
          proveedor: null,
          listaProductos: [],
          observaciones: '',
          total: '',
          transporte: '',
          horaCreado: null,
        }
      },
    },
    mounted () {
      this.receipt = this.receiptToEdit
    },
  }
</script>
